const SKIP_EXACT = ['/', '/tasks', '/notifications', '/assistant']
const SKIP_STARTS_WITH = ['/auth', '/portals', '/settings'] // allows e.g. /settings/notifications

// NOTE: this middleware authenticates client (nuxt) routes
export default defineNuxtRouteMiddleware((to, _from) => {
  const { loggedIn, user } = useUserSession()
  const mustAuthenticate = !loggedIn.value && !to.path.startsWith('/auth')
  const moduleId = to.path.split('/')[1] as keyof User['modules']
  const hasAccessToModule = moduleId && user.value?.modules?.[moduleId]?.enabled

  const skip = SKIP_EXACT.includes(to.path) || SKIP_STARTS_WITH.some(path => to.path.startsWith(path))

  if (skip) return
  // return unauthenticated users to login page
  else if (mustAuthenticate) return navigateTo('/auth/login')
  // return authenticated users to home page if they try to access auth pages
  else if (loggedIn.value && to.path.startsWith('/auth')) return navigateTo('/')
  // return users without access to a module to home page
  else if (!skip && !hasAccessToModule) return navigateTo('/')
})
